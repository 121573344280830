.header-spacer {
    padding-top: 64px !important;
    display: block;
}

.menu-image-container {
    height: calc(100vh - 120px);
    padding-top: 0 !important;

    [data-rmiz-wrap] {
        display: block;
    }

    img {
        // width: 100%;
        margin: auto;
        max-height: calc(100vh - 120px);
    }

    .carousel-control-prev,
    .carousel-control-next {
        opacity: 1;
        align-items: inherit;
        width: auto;
    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        background-color: #000;
        border: 2px solid #fff;
    }

    .carousel-indicators [data-bs-target] {
        width: 75px;
        height: 5px;
        background-color: #000;
        border: 2px solid #fff;
    }

    .carousel-indicators {
        bottom: auto;
    }
}

.image-modal-body
{
    overflow-x: scroll;
}

.image-container {
    margin-top: 64px;

    img {
        max-width: inherit;
    }

    button{
        top:80px;
        right: 20px;
    }
}
