.fix-sidebar {
    position: fixed;
    right: 0;
    top: 0;
    /* background-color: #eee; */
    padding-top: 64px;
    height: 100vh;
    z-index: -1;

    .cart-item-container {
        overflow: auto;
        overflow-x: hidden;
        height: calc(100vh - 230px) !important;
        min-width: 180px;

        .cart-item-border {
            border-bottom: 1px dashed #ddd;
        }
    }

    .btn-cart-quantity .btn{
        font-size: 0.7em;        
    }

    .addons-list{
        font-size: 0.75em;        
    }
}

.fixed-height {
    position: fixed;
    left: 0;
    top: 0;
    padding-top: 64px;
    height: 100vh;

    ion-segment-button {
        max-width: 2000px;
        // background-color:  #eee; //rgba(255, 220, 220, 0.4);
        // background-color:  #eee; //rgba(255, 220, 220, 0.4);
        font-weight: bolder;
    }

    .bg-menu {
        background-color: #eee;
    }
}
