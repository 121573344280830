#overflow-scroll {
    max-height: calc(100vh - 104px);
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    
}

#overflow-scroll::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

