/* .menu-item-container{
    max-width: 300px;
} */
.menu-item-container {
    .badge {
        margin-top: -21px;
    }

    h5.title {
        font-size: 18px;
        color: #000;
        margin-top: -5px;
        margin-bottom: 5px;
    }

    .small {
        font-size: 0.8em;
    }

    .item-image-in-popup {
        width: 100%;
        max-height: 200px;
    }

    .description {
        min-height: 54px;
    }

    .form-floating {
        label {
            padding: 0;
            font-size: 0.9em;
            color: #777;
        }

        select:focus {
            border: none;
        }

        select {
            height: 2.5em;
            padding: 0;
            padding-left: 3px;
            border: none;
            border-radius: 0;
            border-bottom: 1px solid #ddd;
            margin-bottom: 10px;
        }
    }

    button.btn-outline-success {
        margin-right: 11px;
    }
}
