.add-ons-popup {
    padding-top: 64px;
    .item-image-in-popup {
        max-height: 200px;
    }
    .add-ons {
        margin: 10px 5px;
        padding: 5px;
        width: 8rem;
        border: 1px solid #ddd;
        border-radius: 3px;
        background-color: rgba(220, 220, 220, 0.4);

        .add-ons-title {
            font-size: 0.75em;
            font-weight: bold;
            color: #444;
        }

        &.selected {
            background-color: rgba(220, 255, 220, 0.4);
            .add-ons-title {
                text-align: center;
                color: #494;
            }
        }
    }

    .separator {
        border-bottom: 1px dashed #ddd;
    }
}
