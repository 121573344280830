.logo-image {
    max-width: 175px;
}
.contact-number {
    line-height: 0.8em;

    a {
        text-decoration: none;
    }
}
